<template>
  <v-app id="inspire">
    <v-app-bar class="elevation-0" style="height: 64px; position: fixed">
      <v-app-bar-nav-icon @click.stop="handleDrawer" />

      <!-- <v-toolbar-title> -->
        <div class="header-icon ml-3">
          <v-img
            transition="false"
            :src="require('@/assets/logo-pinto-game.svg')"
            class="my-3 cursor-pointer"
            click="switchDashboard(selectedCompany)"
          />
        </div>
      <!-- </v-toolbar-title> -->

      <v-row justify="center">
        <h1 class="red--text">{{ checkEnv }}</h1>
      </v-row>

      <v-spacer class="d-md-block d-none" />

      <div class="input-autocomplete">
        <p-autocomplete
          v-if="userAccount && typeof userAccount === 'object'"
          :items="Object.values(userAccount)"
          :prepend-inner-icon="iconToDisplay"
          :label="$t('label.company_name')"
          item-title="company.company_name"
          item-value="company.company_id"
          @update:modelValue="switchDashboard"
          variant="outlined"
          density="default"
          clearable
          :placeholder="selectedCompany"
          @blur="showAutocomplete = false"
          ref="autocomplete"
          v-model="selectedCompany"
          :model-value="selectedCompany"
          :menu-props="{ maxWidth: 400 }"
          hide-details
        />

        <!-- <p-text-field
          v-else
          v-model="selectedCompany"
          :prepend-inner-icon="iconToDisplay"
          :label="$t('label.company_name')"
          variant="outlined"
          @click="openAutocomplete"
          class="custom-text-field"
          density="default"
          hide-details
        >
          <v-icon class="icon-right" @click="openAutocomplete">
            <slot name="append-outer">mdi-menu-down</slot>
          </v-icon>
        </p-text-field> -->
      </div>

      <v-menu>
        <template v-slot:activator="{ props }">
          <div class="d-md-flex user-name-info d-md-block d-none ml-2">
            <div class="user-name-info__avatar">
              <template
                v-if="
                  dataAuth &&
                  dataAuth.user &&
                  dataAuth.user.current_bg &&
                  dataAuth.user.current_character &&
                  dataAuth.user.current_flame
                "
              >
                <div class="c-avatar">
                  <v-img
                    transition="false"
                    :src="dataAuth?.user?.current_bg"
                    class="c-avatar__bg"
                    width="32"
                    height="32"
                  />
                  <v-img
                    transition="false"
                    :src="dataAuth?.user?.current_character"
                    class="c-avatar__character"
                    width="32"
                    height="32"
                  />
                  <v-img
                    transition="false"
                    :src="dataAuth?.user?.current_flame"
                    class="c-avatar__flame"
                    width="32"
                    height="32"
                  />
                </div>
              </template>

              <template v-else>
                <v-avatar size="32">
                  <v-icon>mdi-account-circle</v-icon>
                </v-avatar>
              </template>
            </div>

            <div class="user-name-info__text">
              <small class="mb-0">
                {{ $t("label.user_id") }}: {{ getUserId }}
              </small>

              <p
                v-if="authData?.user?.full_name"
                class="mb-0"
                id="fullNameStore"
              >
                {{ authData?.user?.full_name }}
              </p>
            </div>
          </div>
          <v-btn icon v-bind="props">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="role !== TYPE_ROLE.GAME_ADMIN"
            link
            :to="`/user-management/edit/${authData?.user?.user_id}`"
          >
            <v-list-item-title class="cursor-pointer">{{
              $t("label.user_info")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="role > TYPE_ROLE.GAME_ADMIN && !isNpcSideBar"
            link
            to="/account-info"
          >
            <v-list-item-title class="cursor-pointer">{{
              $t("title.account_info")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="handleLogout" class="cursor-pointer">{{
              $t("text.logout")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <NpcSideBar v-if="isNpcSideBar" :drawer="drawer" />

    <SideBar v-else :drawer="drawer" :authData="authData" />

    <v-main
      style="
        padding-left: var(--v-layout-left);
        padding-top: 64px;
        min-height: 100vh;
      "
    >
      <v-container class="pa-6" fluid>
        <p-breadcrumbs />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "pinia";
import { authStore } from "@/store";
import { CONSTANTS } from "@/utils";
import { mainStore } from "@/store";
import { defineAsyncComponent, nextTick } from "vue";

export default {
  name: "DashboardLayout",

  setup() {
    const storeAuth = authStore();
    const { TYPE_ROLE } = CONSTANTS;
    const storeMain = mainStore();
    return {
      storeMain,
      TYPE_ROLE,
      storeAuth,
    };
  },

  components: {
    SideBar: defineAsyncComponent(() => import("./SideBar")),
    NpcSideBar: defineAsyncComponent(() => import("./NpcSideBar")),
  },

  computed: {
    ...mapState(authStore, [
      "success",
      "dataAuthMe",
      "userLogin",
      "updateUserLoginSuccess",
      "saveUserLogin",
    ]),
    dataAuth() {
      return this.dataAuthMe;
    },
    authData() {
      const store = localStorage.getItem(CONSTANTS.AUTH_STORAGE);
      return store ? JSON.parse(store) : null;
    },

    role() {
      return this.authData?.user?.role ?? 1;
    },

    company_situation() {
      return this.authData?.company.usage_situation;
    },

    switchable() {
      return this.authData?.user?.switchable ?? false;
    },

    currentPath() {
      return this.$route.path;
    },

    isNpcSideBar() {
      return this.currentPath.substring(1, 4) === "npc";
    },
    checkEnv() {
      return process.env.VUE_APP_MODE === "production"
        ? ""
        : process.env.VUE_APP_MODE.toUpperCase() + " " + "test chatgpt";
    },
    iconToDisplay() {
      return this.isNpcSideBar ? "mdi-account-cog" : "mdi-office-building";
    },
    getUserId() {
      const user_id = this.authData?.user?.user_id;
      if (user_id.includes("_systemadmin")) {
        if (this.currentPath === "/npc-account-management") {
          return user_id.split("_").pop();
        }
        // else {
        //   return user_id.replace("_systemadmin_", "_")
        // }
      }
      return user_id;
    },
  },

  watch: {
    async userLogin() {
      this.userAccount = this.userLogin;
      if (this.currentPath === "/npc-account-management") {
        // this.npcCurrentMenu = this.userAccount.length;
        this.npcCurrentMenu = 0;
        this.selectedCompany = this.$t("text.system_admin");
      } else {
        const user_id = this.authData?.user?.user_id;
        const index = this.userAccount.findIndex(
          (item) => item.user.user_id === user_id
        );
        this.npcCurrentMenu =
          parseInt(localStorage.getItem(CONSTANTS.USER_SELECT)) || index;
        this.selectedCompany =
          this.userAccount[this.npcCurrentMenu]?.company?.company_name;
      }
      const userKey = this.authData.user.email; // Assuming this contains the email
      const request = {
        key: userKey,
        data: JSON.stringify(this.userAccount),
      };
      await this.storeAuth.setUserDropDownList(request);
    },
  },

  data() {
    return {
      drawer: true,
      userAccount: null,
      npcCurrentMenu: 0,
      isNp: false,
      selectedCompany: null,
      showAutocomplete: false,
    };
  },

  methods: {
    handleDrawer() {
      this.drawer = !this.drawer;
    },

    async switchDashboard(selectedItem) {
      this.npcCurrentMenu = this.userAccount.length;
      const index = this.userAccount.findIndex(
        (item) => item.company.company_id === selectedItem
      );

      if (!selectedItem) {
        return; // Handle the case where no item is selected if needed.
      }

      localStorage.setItem(CONSTANTS.USER_SELECT, index);
      const companyData = this.userAccount[index];
      this.selectedCompany = companyData?.company?.company_name;

      await this.storeAuth.updateUserLogin(companyData);

      if (this.updateUserLoginSuccess) {
        this.authData.company = companyData.company;
        this.authData.user = companyData.user;
      }

      if (
        index === 0 &&
        this.authData.company.company_id === this.$t("text.system_admin")
      ) {
        // Handle the NPC case
        this.$router
          .push("/npc-account-management")
          .then(() => location.reload());
      } else {
        // Handle the company case
        if (this.currentPath === "/") {
          location.reload();
        } else {
          this.$router.push("/").then(() => location.reload());
        }
      }
    },

    async handleLogout() {
      await this.storeAuth.logout();
      if (this.success) {
        this.clearCookie();
        this.$router.push({
          name: "Login",
        });
      }
    },

    async openAutocomplete() {
      this.showAutocomplete = true;
      nextTick(() => {
        const autocompleteInput =
          this.$refs.autocomplete.$el.querySelector(".v-field__input");
        if (autocompleteInput) {
          autocompleteInput.focus();
        }
      });
    },

    //Clear cookies
    clearCookie() {
      let cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
  },

  async created() {
    const email = this.authData.user.email;
    await this.storeAuth.getUserDropDownList(email);
    if (this.saveUserLogin?.data) {
      const user_id = this.authData?.user?.user_id;
      this.userAccount = JSON.parse(this.saveUserLogin.data);

      const index = this.userAccount.findIndex(
        (item) => item.user.user_id === user_id
      );

      this.npcCurrentMenu =
        parseInt(localStorage.getItem(CONSTANTS.USER_SELECT)) || index;
      this.selectedCompany =
        this.userAccount[this.npcCurrentMenu]?.company?.company_name;
    } else {
      await this.storeAuth.getUserLogin();
    }
  },
};
</script>

<style lang="scss" scoped>
header {
  z-index: 10 !important;

  &.v-app-bar {
    left: 0 !important;
    border-bottom: 1px solid map-get($grey, "lighten-1");
    background-color: white !important;
  }

  .header-icon {
    width: 165px;
  }
}
.input-autocomplete {
  margin-top: 4px;
  width: 400px;
}
.user-name-info__text {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#fullNameStore {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon-right {
  position: absolute;
  top: 20%;
  right: 20px;
  cursor: pointer;
}
:deep(.custom-text-field input) {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-text-field {
  transform: translateX(10px);
}
.user-name-info {
  padding: 10px;

  &__avatar {
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
}
.c-avatar {
  position: relative;
  width: 32px;
  height: 32px;
  &__bg {
    position: relative;
  }
  &__character {
    top: 0;
    position: absolute;
    height: 32px;
  }
  &__flame {
    top: 0;
    position: absolute;
  }
}

:deep(.v-image__image--cover) {
  background-size: contain;
}

:deep(.v-application__wrap) {
  min-height: fit-content;
  padding-bottom: 24px;
}

// @media #{map-get($display-breakpoints, "xl")} {
//   .v-main {
//     margin-left: auto;
//     margin-right: auto;
//     width: 100%;
//     $TotalpaddingX: map-get($spacers, 6) * 2;
//     max-width: #{$TotalpaddingX + 1600px};
//   }
// }
:deep(.v-autocomplete__selection-text) {
  max-width: 275px;
}
</style>
