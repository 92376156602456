<template>
  <div>
    <v-row class="media-object">
      <!-- Media object -->
      <v-col cols="12" sm="6" :class="hasFixWidth?'upload-flex':''" >
        <PGPreviewImage
          :src="file_url?file_url:url_default"
          :key="file_url"
          :img-name="file_name"
          :img-size="file_size"
          :is-template="is_template"
          :hasDelete="file_url"
          :width="width"
          @remove="removeBackground"
        />
        <!-- <v-alert type="warning" v-if="isGenerateSuccess">
          <div>
            再生成後の画像です。上書きしてもよろしいでしょうか?
          </div>
          <div class="right-align">
            <v-btn variant="text" @click="cancelSaveReGenImage">
              {{ $t("text.cancel") }}
            </v-btn>
            <v-btn color="success" variant="elevated" :loading="isLoadingOverrideImg" @click="saveOverrideImage">
              {{ $t("text.create") }}
            </v-btn>
          </div>
        </v-alert> -->
      </v-col>

      <!-- Main content -->
      <v-col cols="12" sm="6" class="pl-5">
        <p class="text-subtitle-1">{{ $t("text.image_upload") }}</p>
        <div class="d-flex">
          <v-btn
            variant="outlined"
            color="primary"
            class="mb-2"
            @click="dialogTemplateImg = true"
            v-if="showTemplate"
          >
            {{ $t("text.choose_from_templates") }}
          </v-btn>

          <PGUploadBox
            :callback="uploadFile"
          />

          <!-- <div
            class="mt-2 pg-fileupload d-flex justify-center"
            @dragover.prevent
            @drop="onDropFile"
          >
            <div class="align-self-center text-center">
              <v-icon>mdi-image</v-icon>
              <p class="text-caption text-disabled mb-1">
                ここにファイルをドロップ。または
              </p>

              <v-btn
                variant="outlined"
                color="primary"
                elevation="4"
                class="background-white"
                @click="openFile"
              >
                {{ $t("text.select_files") }}
              </v-btn>

              <input
                ref="uploaderBackground"
                class="d-none"
                type="file"
                :accept="accept"
                @change="uploadFile"
              />
            </div>
          </div> -->
        </div>

        <p></p>
        <div class="text-grey-darken-1 pl-5">
            <template v-if="showTemplate">
              <p class="mb-0">※ 画像形式: png, jpg, webp または jpg</p>
              <p class="mb-0">※ 画像サイズ: 縦2048 x 横1024px 以下</p>
            </template>

            <template v-else>
              <p class="mb-0">※ 画像形式: png, jpg, webp</p>
              <p class="mb-0">
                ※ 画像サイズ: 横{{ maxWidth }}px x 縦{{ maxHeight }}
              </p>
              <p class="mb-0">※ 画像容量: {{limitFileSize}} 以下</p>
            </template>
        </div>

        <v-alert
          v-model="isError"
          type="error"
          icon="mdi-alert-octagon-outline"
          close-icon="mdi-close"
          closable
        >
          {{ errorMessage }} <br />
          {{ line2 ? line2 : "" }}
        </v-alert>
        

        <!-- <v-spacer class="border mt-6 mb-4 w-100"/> -->

        <!-- <p class="text-subtitle-1">AI{{ $t("text.image_generation") }}</p>
        <div class="mt-2 d-flex align-center">
          <v-text-field
            v-model="inputPromptText"
            :label="$t('text.image_generation_prompt')"
            variant="outlined"
            density="default"
            color="secondary"
            class="w-80"
          >
          </v-text-field>
          <v-btn
            color="text" 
            variant="elevated"
            :style="{ width: `calc(20% - 16px)` }"
            :loading="isLoadingGenImg"
            class="ml-4"
            @click="handleRegenerateImg"
          >
            {{ $t("text.generate") }}
          </v-btn>
        </div>

        <v-spacer class="border mt-6 mb-4 w-100" />

        <p class="text-subtitle-1">{{ $t("text.text_on_image") }}</p>
        <v-text-field
            v-model="inputText"
            :label="$t('text.text_on_image')"
            variant="outlined"
            density="default"
            color="secondary"
            class="mb-4 w-80"
          >
        </v-text-field>
        <v-color-picker width="80%" canvas-height="36"  mode="hex" v-model="selectTextColor" hide-inputs></v-color-picker>

        <v-spacer class="border mt-6 mb-4 w-100" />

        <p class="text-subtitle-1">{{ $t("text.background_color_setting") }}</p>
        <v-color-picker width="80%" canvas-height="36" mode="rgba" v-model="selectBgColor" hide-inputs></v-color-picker>
        <div class="mt-2 d-flex align-center w-80">
          <p class="title-width text-subtitle-1 mb-0 text-grey-darken-1">{{ $t("text.opacity_bg") }}</p>
          <v-slider
            class="ml-0"
            v-model="transparent"
            color="#8e8e8e"
            :max="100"
            :min="0"
            :step="1"
            thumb-label
          ></v-slider>
        </div> -->

        <div class="mt-2 d-flex justify-start">
          <v-btn
            v-if="hasAIGenerate"
            color="success" 
            variant="elevated"
            elevation="2"
            @click="handleShowEditImage"
          >
            {{ $t("text.image_editing_and_text_image_generation") }}
          </v-btn>
        </div>

      </v-col>
      
    </v-row>

    <CropperImg
      v-if="!isFirstLoading"
      ref="cropComponent"
      :showDialogFlag="showDialogFlag"
      :saveFunc="onSaveImage"
      :arrayImg="arrayImg"
      @input="showDialogFlag = $event"
      :aspectRatio="handleCrop.width / handleCrop.height"
      :handleCrop="handleCrop"
    />

    <dialog-upload
      @input="showDialogUpload = $event"
      :showDialogFlag="showDialogUpload"
      @handleCheckUpload="handleCheckUpload"
    />

    <PGDialogEditImage 
      v-model:show="isShowEditImageDialog" 
      :src="file_url"
      :url_default="url_default"
      :gameType="gameType"
      @image-updated="updateFileUrl"
      :hasCrop="hasAICrop"
      :sizeCrop="handleCrop"
      ref="PGDialogEditImage" />
      <!-- <PGDialogEditImage 
      v-model:show="isShowEditImageDialog" 
      :src="file_url"
      :selectTextColor="selectTextColor"
      :selectBgColor="selectBgColor"
      :input_text="inputText"
      :transparent="transparent"
      @image-updated="updateFileUrl"
      @update:input_text="updateInputText"
      @update:transparent="updateTransparent"
      @update:selectTextColor="updateTextColor"
      @update:selectBgColor="updateBgColor"
      ref="PGDialogEditImage" /> -->
  </div>
</template>

<script>
import { imageSize, getSizeFile, errorFileName, CONSTANTS, auth } from "@/utils";
import { defineAsyncComponent } from "vue";
import { areaStore, mainStore } from "@/store";

export default {
  name: "PGUploadImg",
  components: {
    PGPreviewImage: defineAsyncComponent(() =>
      import("@/components/PGPreviewImage")
    ),
    PGDialogEditImage: defineAsyncComponent(() =>
      import("@/components/PGDialogEditImage")
    ),
    PGUploadBox: defineAsyncComponent(() =>
      import("@/components/PGUploadBox")
    ),
  },
  props: {
    getObjImage: { type: Function, default: () => {} },
    url: { type: String, default: null },
    url_default: { type: String, default: "" },
    name: { type: String, default: null },
    size: { type: Number, default: null },
    error: { type: Boolean, default: false },
    message: { type: String, default: "" },
    required: { type: Boolean, default: false },
    showTemplate: { type: Boolean, default: false },
    accept: { type: String, default: ".png, .jpg, .webp" },
    isTemplate: { type: Boolean, default: false },
    maxWidth: { type: Number, default: 250 },
    maxHeight: { type: Number, default: 250 },
    limitFileSize: {type: String, default: "200KB"},
    urlGenerateQuestion: { type: String, default: null },
    hasAIGenerate: { type: Boolean, default: true },
    hasAICrop: { type: Boolean, default: false },
    handleCrop: {
      type: Object,
      default: CONSTANTS.HANDLE_CROP_IMG_DORAKUE
    },
    width: { type: Number, default: null },
    hasFixWidth: { type: Boolean, default: false },
  },
  setup() {
    const storeArea = areaStore();
    const storeMain = mainStore();
    return {
      storeMain,
      storeArea,
    };
  },
  data() {
    return {
      active: false,
      getSizeFile: getSizeFile,
      showDialogUpload: false,
      dataFile: null,
      resizeHeight: 700,
      resizeWidth: 1154,
      arrayImg: [],
      showDialogFlag: false,
      isFirstLoading: true,
      dialogTemplateImg: false,
      dialogPrev: false,
      imageSelect: null,
      isError: false,
      errorMessage: "",
      file_url: this.url,
      file_name: this.name,
      file_size: this.size,
      file: null,
      is_template: this.isTemplate,
      templateObj: null,
      line2: "",
      isChangeImg: false,
      isShowEditImageDialog: false,
      isLoadingGenImg: false,
      selectTextColor: "#000000",
      selectBgColor: { r: 0, g: 0, b: 0, a: 0 },
      transparent: 100,
      inputText: "",
      gameType: CONSTANTS.GAME.DORAKUE,
      // inputPromptText: "",
      // isGenerateSuccess: false,
      // saved_url: this.url,
      // saved_name: this.name,
      // isLoadingOverrideImg: false,
    };
  },
  computed: {
    isErrorV: {
      get() {
        return this.error;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    companyId() {
      return auth()?.company.company_id;
    },
  },
  watch: {
    file() {
      this.getObjImage({
        file: this.file,
        file_url: this.file_url,
        name: this.file_name,
        size: this.file_size,
        error: this.isError,
        is_template: this.is_template,
        templateObj: this.templateObj,
      });
    },
    file_url() {
      this.getObjImage({
        file: this.file,
        file_url: this.file_url,
        name: this.file_name,
        size: this.file_size,
        error: this.isError,
        is_template: this.is_template,
        templateObj: this.templateObj,
      });
    },
    file_name() {
      this.getObjImage({
        file: this.file,
        file_url: this.file_url,
        name: this.file_name,
        size: this.file_size,
        error: this.isError,
        is_template: this.is_template,
        templateObj: this.templateObj,
      });
    },
    isErrorV() {
      this.isError = this.isErrorV;
      if (!this.isErrorV) {
        this.errorMessage = "";
      }
    },
    message() {
      this.errorMessage = this.message;
    },
  },
  methods: {
    async handleCheckUpload(checkUpload) {
      const files = this.dataFile;
      if (checkUpload) {
        const obj = await this.readFile(files[0]);
        this.file_url = obj.url;
        this.file_name = obj.name;
        this.file_size = obj.size;
        this.file = obj.file;
        this.isError = obj.error;
        this.$emit("input", obj.error);
        this.errorMessage = obj.message;
        this.$emit("isCheckCrop", false);
      } else {
        this.arrayImg = [];
        const file = await this.readFileCrop(files[0]);
        if (file.error) {
          this.isError = file.error;
          this.errorMessage = file.message;
          return;
        }

        if (!file.error) {
          const loader = this.$loading.show({
            loader: "dots",
          });
          const fileInfo = files[0];
          this.arrayImg.push({
            id: Date.now(),
            fileName: fileInfo.name,
            type: fileInfo.type,
            file: fileInfo,
            errorMessage: file.message,
            isError: file.error,
          });
          // Convert img to base64
          if (typeof FileReader === "function") {
            const reader = new FileReader();
            reader.onload = (event) => {
              let updatedImg = this.arrayImg[0];
              updatedImg.url = event.target.result;
              this.arrayImg[0] = updatedImg;
              if (this.isFirstLoading) {
                this.isFirstLoading = false;
              } else {
                this.$refs.cropComponent.showLoading();
                this.$refs.cropComponent.replaceImage(this.arrayImg[0].url);
              }
              this.showDialogFlag = true;
              setTimeout(() => {
                loader.hide();
              }, 200);
            };
            reader.readAsDataURL(fileInfo);
          }
        }
      }
    },
    async onSaveImage(data) {
      this.file_url = data[0].url;
      this.file_name = data[0].fileName;
      this.file_size = data[0].file.size;
      this.file = data[0].file;
      this.isError = data[0].isError;
      this.$emit("input", data[0].errorMessage);
      this.errorMessage = data[0].errorMessage;
      this.$emit("isCheckCrop", true);
    },
    updateFileUrl({ dataURL, blob }) {
      let fileName = this.file_name;
      if (!fileName) {
        fileName = `${Date.now()}.png`
      } else {
        if (![".webp", ".png", ".jpg"].some((ext) => fileName.endsWith(ext))) {
          fileName = `${fileName}.png`;
        }
      }
      const file = new File([blob], fileName, { type: blob.type });
      this.file_url = dataURL;
      this.file = file;
    },
    // updateInputText(value) {
    //   this.inputText = value;
    // },
    // updateTransparent(value) {
    //   this.transparent = value;
    // },
    // updateTextColor(value) {
    //   this.selectTextColor = value;
    // },
    // updateBgColor(value) {
    //   this.selectBgColor = value;
    // },
    getImageSelect(obj) {
      this.file_url = obj.url;
      this.file_name = obj.name;
      this.file = obj.file;
      this.file_size = obj.size;
      this.dialogTemplateImg = false;
      this.is_template = true;
      this.templateObj = obj;
    },
    openFile() {
      this.$refs.uploaderBackground.value = "";
      this.$refs.uploaderBackground.click();
    },
    async readFile(file) {
      this.templateObj = null;
      this.is_template = false;
      const size = !this.showTemplate ? 1204800 : 5000000;
      const types = this.showTemplate
        ? ["image/png", "image/webp"]
        : ["image/png", "image/webp"];
      const width = this.showTemplate ? 1154 : this.maxWidth;
      const height = this.showTemplate ? 700 : this.maxHeight;
      const result = {
        file: null,
        url: "",
        name: null,
        size: null,
        error: false,
        message: "",
      };
      if (
        types.includes(file.type) ||
        file.name.toLowerCase().includes("jpg")
      ) {
        if (file) {
          const objectUrl = URL.createObjectURL(file);
          const imageDimensions = await imageSize(objectUrl);
          if (size < file.size) {
            result.error = true;
            result.message = `${file.name} のファイルサイズが大きすぎます。
			${this.showTemplate ? "5MB" : "1MB"} 以下に修正してください。`;
          } else if (
            imageDimensions.width > width ||
            imageDimensions.height > height
          ) {
            result.error = true;
            result.message = `${file.name} の画像サイズが大きすぎます。`;
            this.line2 = `縦${height} x 横${width}px 以下に修正してください。`;
          } else {
            result.error = false;
            result.file = file;
            result.name = file.name;
            result.size = file.size;
            result.url = objectUrl;
            this.$emit("isChangeImg", (this.isChangeImg = true));
          }
        }
      } else {
        result.error = true;
        result.message = errorFileName(file.name);
      }
      return result;
    },
    async readFileCrop(file) {
      this.templateObj = null;
      this.is_template = false;
      const size = !this.showTemplate ? 10485760 : 10485760;
      const types = this.showTemplate
        ? ["image/png", "image/webp"]
        : ["image/png", "image/webp"];

      const result = {
        file: null,
        url: "",
        name: null,
        size: null,
        error: false,
        message: "",
      };
      if (
        types.includes(file.type) ||
        file.name.toLowerCase().includes("jpg")
      ) {
        if (file) {
          const objectUrl = URL.createObjectURL(file);
          if (size < file.size) {
            result.error = true;
            result.message = `${file.name} のファイルサイズが大きすぎます。
			${this.showTemplate ? "10MB" : "10MB"} 以下に修正してください。`;
          } else {
            result.error = false;
            result.file = file;
            result.name = file.name;
            result.size = file.size;
            result.url = objectUrl;
            this.$emit("isChangeImg", (this.isChangeImg = true));
          }
        }
      } else {
        result.error = true;
        result.message = errorFileName(file.name);
      }
      return result;
    },
    async uploadFile(files) {
      console.log(files);
      if (files.length <= 0 || !files || !files[0]) {
        return;
      }
      this.dataFile = files;
      this.showDialogUpload = true;
    },
    async onDropFile(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      console.log(files);
      this.dataFile = files;
      this.showDialogUpload = true;
    },
    removeBackground() {
      this.file_url = null;
      this.file = null;
      this.file_size = 0;
      if (this.required) {
        this.isError = true;
        this.errorMessage = this.showTemplate
          ? `画像ファイルが選択されていません。
          画像ファイルをアップロードするか、テンプレートから選んでください。`
          : this.$t("message.please_upload_an_image_file");
      }
    },
    // async handleRegenerateImg(){
    //   this.isLoadingGenImg = true;
    //   // write your code here
    //   const params = this.$route.params;
    //   await this.storeArea.reGenerateImgDorakue(
    //     this.companyId,
    //     params.gameId,
    //     params.areaId,
    //     params.courseId,
    //     params.stageId,
    //     params.problemId,
    //     { note: this.inputPromptText }
    //   )
    //   this.isLoadingGenImg = false;
    //   if (this.storeArea.success) {
    //     this.file_url = this.storeArea.reGenerateImageUrl;
    //     const date = Date.now();
    //     this.file_name = `regenerate_${date}`;
    //     this.isGenerateSuccess = true;
    //   }
    // },
    // async saveOverrideImage() {
    //   this.isLoadingOverrideImg = true;
    //   await this.storeArea.proxyImg(this.companyId, this.file_url);
    //   if (this.storeArea.imageUrl) {
    //     const getFile = await window.convertURLToPNG(this.storeArea.imageUrl, this.file_name);
    //     const convertFile = await window.runEncodePNGFile(getFile, 150);
    //     const formData = new FormData();
    //     formData.append("gen_file_url_ktx", convertFile[0]);
    //     formData.append("gen_file_url", this.file_url);
    //     formData.append("gen_file_name", this.file_name);

    //     const params = this.$route.params;
    //     await this.storeArea.saveReGenerateImgDorakue(
    //       this.companyId,
    //       params.gameId,
    //       params.areaId,
    //       params.courseId,
    //       params.stageId,
    //       params.problemId,
    //       formData,
    //     );
    //     if (this.storeArea.successSave) {
    //       this.isGenerateSuccess = false;
    //     }
    //   }
    //   this.isLoadingOverrideImg = false;
    // },
    handleShowEditImage() {
      this.isShowEditImageDialog = true;
    },
    // cancelSaveReGenImage() {
    //   this.isGenerateSuccess = false;
    //   this.file_url = this.saved_url;
    //   this.file_name = this.saved_name;
    // }
  },
};
</script>

<style lang="scss" scoped>
.upload-flex{
  flex:0 0 0%;
}
.pg-fileupload {
  border: 2px dashed map-get($grey, "lighten-1");
  width: 100%;
  height: 120px;
  border-radius: 8px;
  background: map-get($grey, "lighten-3");
}

:deep(.v-color-picker__controls) {
  padding: 0;
}
:deep(.v-color-picker-preview__dot) {
  height: 20px;
  width: 20px;
}
:deep(.v-input__details) {
  display: none;
}
:deep(.v-input__prepend) {
  margin: 0;
  min-width: 100px;
}
:deep(.v-slider__label){
  margin: 0;
}

.title-width {
  min-width: 100px;
}

.right-align {
  display: flex;
  justify-content: end;
}
</style>
